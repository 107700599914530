<script setup lang="ts">
import type { Pronoun } from '~/src/classes.ts';
import type { GrammarTable, MorphemeCell, PronounVariant, SectionDefinition } from '~/src/pronouns/grammarTables.ts';

const props = defineProps<{
    selectedPronoun: Pronoun;
    comprehensive: boolean;
    counter: number;
}>();

interface Declension {
    name: string;
    numerus: 'singular' | 'plural';
    icon?: string;
    abbreviation: string;
}

const cases = ['n', 'g', 'd', 'a'];
const declensions: Declension[] = [
    {
        name: 'Singular Femininum',
        numerus: 'singular',
        icon: 'venus',
        abbreviation: 'f',
    },
    {
        name: 'Singular Maskulinum',
        numerus: 'singular',
        icon: 'mars',
        abbreviation: 'm',
    },
    {
        name: 'Singular Neutrum',
        numerus: 'singular',
        icon: 'neutrum',
        abbreviation: 'n',
    },
    {
        name: 'Singular neutral',
        numerus: 'singular',
        icon: 'neuter',
        abbreviation: 'x',
    },
    {
        name: 'Plural',
        numerus: 'plural',
        abbreviation: 'pl',
    },
];

const simpleGrammarTables: GrammarTable[] = [
    {
        columnHeader: [
            {
                name: 'Nominativ',
                short: 'N',
            },
            {
                name: 'Possessiv',
                short: 'P',
            },
            {
                name: 'Dativ',
                short: 'D',
            },
            {
                name: 'Akkusativ',
                short: 'A',
            },
        ],
        sections: [
            {
                variants: [
                    {
                        morphemeCells: [
                            'pronoun_n',
                            {
                                morpheme: 'possessive_determiner_m_n',
                                highlightsMorphemes: ['determiner', 'pronoun'].flatMap((possessiveKind) => {
                                    return ['f', 'm', 'n', 'x', 'pl'].flatMap((genus) => {
                                        return cases.flatMap((casus) => {
                                            return `possessive_${possessiveKind}_${genus}_${casus}`;
                                        });
                                    });
                                }),
                            },
                            'pronoun_d',
                            'pronoun_a',
                        ],
                    },
                ],
            },
        ],
    },
];
const comprehensiveGrammarTables: GrammarTable[] = [
    {
        columnHeader: [
            {
                name: 'Nominativ',
                short: 'N',
            },
            {
                name: 'Genitiv',
                short: 'G',
            },
            {
                name: 'Dativ',
                short: 'D',
            },
            {
                name: 'Akkusativ',
                short: 'A',
            },
        ],
        sections: [
            {
                header: {
                    name: 'Personalpronomen',
                    short: 'Personalp.',
                },
                variants: {
                    base: 'pronoun',
                    type: 'case',
                },
            },
            {
                header: {
                    name: 'Possessivartikel',
                    short: 'Possessivart.',
                },
                variants: {
                    base: 'possessive_determiner',
                    type: 'declension-with-case',
                },
            },
            {
                header: {
                    name: 'Possessivpronomen',
                    short: 'Possessivp.',
                },
                variants: {
                    base: 'possessive_pronoun',
                    type: 'declension-with-case',
                },
            },
            {
                header: {
                    name: 'Relativpronomen',
                    short: 'Relativp.',
                },
                variants: {
                    base: 'relative',
                    type: 'case',
                },
            },
            {
                header: {
                    name: 'Demonstrativpronomen',
                    short: 'Demonstrativp.',
                },
                variants: {
                    base: 'demonstrative',
                    type: 'case',
                },
            },
        ],
    },
    {
        columnHeader: [
            {
                name: '',
                short: '',
            },
        ],
        sections: [
            {
                header: {
                    name: 'weitere Pronomen',
                    short: 'weitere P.',
                },
                variants: [
                    {
                        name: 'gleicher Art',
                        morphemeCells: ['pronoun_equal'],
                    },
                    {
                        name: 'zugehörig',
                        morphemeCells: ['possessive_pronoun_substantivized'],
                    },
                ],
            },
            {
                header: {
                    name: 'Adverbien',
                    short: 'Adverb.',
                },
                variants: [
                    {
                        name: 'wegen',
                        morphemeCells: ['adverb_because'],
                    },
                    {
                        name: 'damals',
                        morphemeCells: ['adverb_back_then'],
                    },
                    {
                        name: 'von',
                        morphemeCells: ['adverb_by'],
                    },
                ],
            },
            {
                header: {
                    name: 'spezifische Adjektive',
                    short: 'spez. Adj.',
                },
                variants: [
                    {
                        morphemeCells: ['adjective_back_then'],
                    },
                ],
            },
        ],
    },
];

const grammarTables = computed((): GrammarTable[] => {
    return props.comprehensive ? comprehensiveGrammarTables : simpleGrammarTables;
});

const morphemesByCase = (morphemeBase: string): MorphemeCell[] => {
    return cases.map((caseAbbreviation) => ({ morpheme: `${morphemeBase}_${caseAbbreviation}` }));
};

const expandVariantsForSection = (sectionVariants: SectionDefinition['variants']): PronounVariant[] => {
    if (Array.isArray(sectionVariants)) {
        return sectionVariants.map((sectionVariant) => {
            const morphemeCells = sectionVariant.morphemeCells.map((morphemeCell) => {
                if (typeof morphemeCell === 'string') {
                    return { morpheme: morphemeCell };
                }
                return { ...morphemeCell, highlightsMorphemes: new Set(morphemeCell.highlightsMorphemes) };
            });
            return { ...sectionVariant, morphemeCells };
        });
    } else {
        const morphemeBase = sectionVariants.base;
        switch (sectionVariants.type) {
            case 'case':
                return [{ morphemeCells: morphemesByCase(morphemeBase) }];
            case 'declension-with-case':
                return declensions.map((declension) => ({
                    name: declension.name,
                    numerus: declension.numerus,
                    icon: declension.icon,
                    morphemeCells: morphemesByCase(`${morphemeBase}_${declension.abbreviation}`),
                }));
            default:
                throw new Error(`variant type ${sectionVariants.type} is unknown`);
        }
    }
};
</script>

<template>
    <section>
        <h2 class="h4">
            <Icon v="spell-check" />
            <T>pronouns.grammarTable</T><T>quotation.colon</T>
        </h2>
        <PronounsGrammarTable
            v-for="(grammarTable, t) in grammarTables"
            :key="t"
            :grammar-table="grammarTable"
            :expand-variants-for-section="expandVariantsForSection"
            :pronoun="selectedPronoun"
            :counter="counter"
        />
    </section>
</template>
